<template>
    <div class="box">
        <div class="header">
            <span>订单日期：</span>
            <datePicker @selector="selector"/>
        </div>
        <div class="content">
            <el-table 
                :data="OrderList" 
                ref="table"
                height="calc(100vh - 240px)" 
                v-loading="loading"
                element-loading-text="拼命加载中">
                <el-table-column
                    fixed
                    align="center"
                    type="index"
                    label="序号"
                    width="100">
                </el-table-column>
                <el-table-column
                    align="center"
                    prop="date"
                    label="订单日期">
                </el-table-column>
                <el-table-column
                    align="center"
                    prop="settlement_amount"
                    label="结算金额">
                </el-table-column>
                <el-table-column align="center" width="208" label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" @click="skipTo(scope.row.date,'RechargeProduct')">查看</el-button>
                        <el-button type="text" @click="download(scope.row.date,'RechargeProduct')">
                            <font color="#FF6056">下载</font>
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="footer">
            <paging :sonpagingData="pagingData" @sonHandleSizeChange="handleSizeChange" @sonHandleCurrentChange="handleCurrentChange"/>
        </div>
    </div>
</template>
<script>
import FileSaver from 'file-saver'
import XLSX from 'xlsx'
import paging from '../../components/paging.vue'
import datePicker from '../../components/datePicker.vue'
export default {
    components:{
        paging,
        datePicker
    },
    data(){
        return{
            start_time:'',
            end_time:'',
            // 分页数据
            pagingData:{
                page:1,
                max_page:15,
                count:0,
            },
            OrderList:[],
            // 加载状态
            loading:false,
            tab_text:'全部',
            tabs:['全部','待到账','已到账'],
            tabIndex:0
        }
    },
    updated(){
        this.$refs.table.doLayout()
    },
    created(){
        this.getOrderList()
    },
    methods:{
        //获取数据列表
        async getOrderList(){
            this.loading=true
            const {data:res}=await this.$http.get('/c-admin/order_reconciliation_statistics/',{
                params:{
                    page:this.pagingData.page,
                    max_page:this.pagingData.max_page,
                    start_time:this.start_time,
                    end_time:this.end_time,
                    type:"RechargeProduct"
                }
            })
            if(res.status!==200) return this.$message({ showClose: true, message: res.message, type: 'error'})
            this.OrderList=res.data.data
            this.pagingData.count=res.data.count
            this.loading=false
        },
        // 查看
        skipTo(date,type){
            this.$router.push({path:'/orderVerifyItem', query: { date: date,type:type}})
        },
        // 下载
        async download(date,type){
            const {data:res} = await this.$http.get('/c-admin/order_reconciliation/',{
                params:{
                    type:type,
                    date:date,
                    download:true
                }
            })
            if(res.status!==200) return this.$message({ showClose: true, message: '下载数据失败', type: 'error'})
            console.log('下载',res)
            const downloadData = res.data
            require.ensure([], () => {
                const { export_json_to_excel } = require('@/Excel/Export2Excel');//注意这个Export2Excel路径
                const tHeader = [ '订单ID','成本价','实付金额','结算金额'];   // 上面设置Excel的表格第一行的标题
                const filterVal = ['order_id','cost_price','amount','settlement_amount']; // 上面的index、nickName、name是tableData里对象的属性key值
                const list = downloadData;  //把要导出的数据tableData存到list
                const data = this.formatJson(filterVal, list);
                export_json_to_excel(tHeader, data, date + '直充订单对账');//最后一个是表名字
            })
        },
        // 时间格式
        timeFormat(originVal) {
            const dt = new Date(originVal)
            const y = dt.getFullYear()
            const m = (dt.getMonth() + 1 + '').padStart(2, '0')
            const d = (dt.getDate() + '').padStart(2, '0')
            const hh = (dt.getHours() + '').padStart(2, '0')
            const mm = (dt.getMinutes() + '').padStart(2, '0')
            const ss = (dt.getSeconds() + '').padStart(2, '0')
            return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
        },
        formatJson(filterVal,jsonData) {
            return jsonData.map(v => filterVal.map(j => v[j]))
        },
        // 接收日期选择的数据
        selector(data){
            console.log(data)
            this.start_time=data.start_time
            this.end_time=data.end_time
            this.getOrderList()
        },
        // 监听pagesize改变
        handleSizeChange(val) {
            console.log(val)
            this.pagingData.max_page=val
            this.getOrderList()
        },
        // 监听页码值
        handleCurrentChange(val) {
            console.log(val)
            this.pagingData.page=val
            this.getOrderList()
        }
    }
}
</script>
<style lang="less" scoped>
.box{
    padding: 0 24px;
    .header{
        height: 42px;
        display: flex;
        align-items: center;
        div{
            position: relative;
            display: flex;
            align-items: center;
            .tab-box{
                display: flex;
                width: 100px;
                height: 40px;
                border: 1px solid #BABEC7;
                justify-content: center;
                align-items: center;
                color: #828494;
                font-size: 14px;
            }
            .tab-box:hover .tabs{
                display: block!important;
                cursor:pointer
            }
            .tabs{
                display: none!important;
                position: absolute;
                z-index: 99999999;
                top: 41px;
                display: flex;
                flex-direction: column;
                // border-top: 1px solid #BABEC7;
                border-left: 1px solid #BABEC7;
                background-color: white;
                div{
                    display: flex;
                    width: 100px;
                    height: 40px;
                    justify-content: center;
                    align-items: center;
                    border-bottom: 1px solid #BABEC7;
                    border-right: 1px solid #BABEC7;
                    color: #828494;
                    font-size: 14px;
                }
            }
        }
        
    }
    .content{
        padding-top: 20px;
        
    }
}
</style>